<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="8"
        md="8"
        sm="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Items Master List
            </h1>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-right mb-2 ml-auto"
        md="4"
        cols="4"
        sm="3"
      >
        <b-button
          v-if="canViewThisAction('create', 'ItemsMaster')"
          variant="success"
          :to="{ name: 'purchasing-items-master-create' }"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
            size="16"
          />
          <span class="align-middle d-md-block d-none">Create Items</span>
          <span class="align-middle d-md-none d-block">New</span>
        </b-button>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <div class="action-left pt-50">
              <b-form-checkbox
                :checked="selectAllItemsMasterCheckbox"
                :indeterminate="isSelectAllItemsMasterCheckboxIndeterminate"
                @change="selectAllCheckboxUpdate"
              >
                Select All
              </b-form-checkbox>
            </div>
            <b-dropdown
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="mx-1"
              right
              text="Bulk Actions"
              variant="primary"
              :disabled="!selectedItemsMaster.length"
            >
              <!-- <b-dropdown-item @click="downloadBulkCSV">
                Download CSV
              </b-dropdown-item> -->
              <b-dropdown-item
                v-if="canViewThisAction('update', 'ItemsMaster')"
                v-b-modal.modal-update-status-all-items-master-bulk
              >
                Update Status
              </b-dropdown-item>
            </b-dropdown>
            <span class="text-muted">Showing {{ dataMeta.from }} - {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <a
                href="javascript:void(0)"
                class="nav-link nav-link-search"
                @click="showSearchBar = !showSearchBar"
              >
                <feather-icon
                  icon="SearchIcon"
                  size="21"
                  class="mr-50"
                />
                <span class="align-middle">Search</span>
              </a>
              <b-input-group
                v-if="showSearchBar"
                class="input-group-merge mr-50"
              >
                <b-form-input
                  v-model="searchQuery"
                  placeholder="Search..."
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    @click="searchQuery = ''; showSearchBar = !showSearchBar"
                  />
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refItemsMasterTable"
        class="position-relative event_list"
        :class="{ low_items: currentDataLength < 4 }"
        :items="fetchItemsMaster"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        no-sort-reset
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Role -->
        <template #cell(itemNo)="data">
          <div class="text-nowrap">
            <b-form-checkbox
              :checked="selectedItemsMaster.includes(data.item._id)"
              @change="toggleSelectedItemsMaster(data.item._id)"
              @click.native.stop
            >
              <b-button
                v-if="canViewThisAction('show', 'ItemsMaster')"
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                variant="none"
                class="text-bold-black align-middle detail-view-id"
                :to="{ name: 'purchasing-items-master-show', params: { id: data.item._id } }"
              >
                {{ data.item.itemNo }}
              </b-button>
              <b-button
                v-else
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                variant="none"
                class="text-bold-black align-middle detail-view-id not-button"
              >
                {{ data.item.itemNo }}
              </b-button>
            </b-form-checkbox>
          </div>
        </template>

        <template #cell(supplier)="data">
          <div class="text-nowrap">
            <b-button
              v-if="canViewThisAction('show', 'Vendor')"
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="link"
              class="align-middle detail-view-id"
              :to="{ name: 'purchasing-vendors-show', params: { id: data.item.supplier ? data.item.supplier._id : 0 } }"
            >
              {{ data.item.supplier.companyName }}
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="none"
              class="align-middle detail-view-id not-button"
            >
              {{ data.item.supplier.companyName }}
            </b-button>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            :variant="`light-${resolveItemsMasterstatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template
          v-if="canViewThisAction('show', 'ItemsMaster') || canViewThisAction('update', 'ItemsMaster') || canViewThisAction('delete', 'ItemsMaster')"
          #cell(actions)="data"
        >
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <!-- <b-dropdown-item
              v-if="canViewThisAction('show', 'ItemsMaster')"
              :to="{ name: 'purchasing-items-master-show', params: { id: data.item._id } }"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">View</span>
            </b-dropdown-item> -->

            <b-dropdown-item
              v-if="canViewThisAction('update', 'ItemsMaster')"
              :to="{ name: 'purchasing-items-master-edit', params: { id: data.item._id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="canViewThisAction('delete', 'ItemsMaster')"
              @click="deleteEntity(data.item._id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
    </b-card>

    <div
      v-if="totalItemsMaster > perPage"
      class="mx-2 mb-2"
    >
      <b-row>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center pagination-content"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalItemsMaster"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            align="center"
            prev-text="Prev"
            next-text="Next"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
              <span>Prev</span>
            </template>
            <template #next-text>
              <span>Next</span>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
    <b-modal
      id="modal-update-status-all-items-master-bulk"
      ref="update-status-modal-all-bulk"
      modal-class="status-update-modal"
      cancel-variant="flat-primary"
      ok-variant="success"
      ok-title="Update"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Update Status"
      @ok="updateBulkStatus"
    >
      <b-form @submit.prevent="updateBulkStatus">
        <validation-observer
          ref="allItemsMastersBulkStatusEditForm"
        >
          <b-form-group
            label="Status*"
            label-for="h-event-status"
          >
            <validation-provider
              #default="{ errors }"
              name="Status"
              vid="status"
              rules="required"
            >
              <v-select
                id="h-event-status"
                v-model="bulkStatus"
                label="title"
                :options="statusRadioOptions"
                :reduce="title => title.code"
                :clearable="false"
                @input="bulkStatusValidation == true ? bulkStatusValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="bulkStatusValidation"
                class="text-danger"
              >
                {{ bulkStatusError }}
              </small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BButton, BCol, BFormInput, BTable, BInputGroup, BInputGroupAppend,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormCheckbox, BFormGroup, BForm,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, onUnmounted, computed } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import useItemsMasterList from './useItemsMasterList'
import itemsMasterStoreModule from './itemsMasterStoreModule'

export default {
  components: {
    BForm,
    BCard,
    BRow,
    BButton,
    BCol,
    BFormInput,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BFormGroup,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      bulkStatus: 'published',
      bulkStatusValidation: false,
      bulkStatusError: 'Valid status is required',
      statusRadioOptions: [
        { title: 'Pending', code: 'pending' },
        { title: 'Published', code: 'published' },
        { title: 'Hidden', code: 'hidden' },
      ],
      user: store.state.auth.userData,
      required,
    }
  },

  methods: {
    updateBulkStatus() {
      this.$refs.allItemsMastersBulkStatusEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('status', this.bulkStatus)
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.selectedItemsMaster.length; i++) {
            formData.append('itemsMasters[]', this.selectedItemsMaster[i])
          }

          this.$http.patch('purchase/items-master/action/bulk/status/update', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(() => {
              this.refetchData()
              this.selectedItemsMaster = []
              this.$swal({
                title: 'Status Updated!',
                html: 'The status for selected items master has been updated',
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'bulkStatus') {
                    this.bulkStatusError = validationError.msg
                    this.bulkStatusValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
    deleteEntity(id) {
      this.$swal({
        title: 'Warning!',
        text: ' Are you sure you want to delete this?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Delete!',
        cancelButtonText: 'No, Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.toDeletedID = id
            this.deleteItemsMaster()
          }
        })
    },
  },
  setup() {
    const showSearchBar = ref(false)
    const showFilterOptions = ref(false)
    const ITEMS_MASTER_APP_STORE_MODULE_NAME = 'app-items-master'

    // Register module
    if (!store.hasModule(ITEMS_MASTER_APP_STORE_MODULE_NAME)) store.registerModule(ITEMS_MASTER_APP_STORE_MODULE_NAME, itemsMasterStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ITEMS_MASTER_APP_STORE_MODULE_NAME)) store.unregisterModule(ITEMS_MASTER_APP_STORE_MODULE_NAME)
    })

    const { canViewThisAction } = useAclUtils()

    const {
      fetchItemsMaster,
      tableColumns,
      perPage,
      currentPage,
      itemsMasterData,
      totalItemsMaster,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refItemsMasterTable,
      refetchData,

      toDeletedID,
      deleteItemsMaster,
      currentDataLength,

      // UI
      resolveItemsMasterstatusVariant,
    } = useItemsMasterList()

    const selectedItemsMaster = ref([])
    const toggleSelectedItemsMaster = ItemsMasterId => {
      const ItemsMasterIndex = selectedItemsMaster.value.indexOf(ItemsMasterId)

      if (ItemsMasterIndex === -1) selectedItemsMaster.value.push(ItemsMasterId)
      else selectedItemsMaster.value.splice(ItemsMasterIndex, 1)
    }
    const selectAllItemsMasterCheckbox = computed(() => itemsMasterData.value.length && (itemsMasterData.value.length === selectedItemsMaster.value.length))
    const isSelectAllItemsMasterCheckboxIndeterminate = computed(() => Boolean(selectedItemsMaster.value.length) && itemsMasterData.value.length !== selectedItemsMaster.value.length)
    const selectAllCheckboxUpdate = val => {
      selectedItemsMaster.value = val ? itemsMasterData.value.map(flow => flow._id) : []
    }

    return {

      // Sidebar

      fetchItemsMaster,
      tableColumns,
      perPage,
      currentPage,
      totalItemsMaster,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refItemsMasterTable,
      refetchData,
      showSearchBar,
      showFilterOptions,
      toDeletedID,
      deleteItemsMaster,
      currentDataLength,

      // Filter
      canViewThisAction,

      // UI
      resolveItemsMasterstatusVariant,

      selectAllItemsMasterCheckbox,
      isSelectAllItemsMasterCheckboxIndeterminate,
      selectedItemsMaster,
      toggleSelectedItemsMaster,
      selectAllCheckboxUpdate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
